// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appUserDashboards/getAllData', async () => {  
  const response = await useJwt.getAllUserDashboardData()
  return response.data
})

export const getData = createAsyncThunk('appUserDashboards/getData', async params => {
  const response = await useJwt.getUserDashboardData(params)
  return {
    params,
    data: response.data.dashboards,
    totalPages: response.data.total
  }
})

export const getDashboard = createAsyncThunk('appUserDashboards/getUserDashboard', async id => {
  const response = await useJwt.getUserDashboard({ id })
  return response.data
})

export const addDashboard = createAsyncThunk('appUserDashboards/addUserDashboard', async (dashboard, { dispatch, getState }) => {
  await useJwt.addUserDashboard(dashboard)  
  await dispatch(getData(getState().dashboards.params))
  await dispatch(getAllData())
  return dashboard
})

export const updateDashboard = createAsyncThunk('appUserDashboards/updateUserDashboard', async (dashboard, { dispatch, getState }) => {
  await useJwt.updateUserDashboard(dashboard)  
  await dispatch(getData(getState().dashboards.params))
  await dispatch(getAllData())
  return dashboard
})

export const deleteDashboard = createAsyncThunk('appUserDashboards/deleteUserDashboard', async (id, { dispatch, getState }) => {
  await useJwt.deleteUserDashboard({ id })
  await dispatch(getData(getState().dashboards.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('appUserDashboards/toggleLoading', async () => {
  return true
})


export const appUserDashboardsSlice = createSlice({
  name: 'appUserDashboards',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDashboard: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.selectedDashboard = action.payload
        state.loading = false
      })
      .addCase(updateDashboard.fulfilled, (state, action) => {
        state.selectedDashboard = {...state.selectedDashboard, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appUserDashboardsSlice.reducer