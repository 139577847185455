// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Timezone conversion
import moment from 'moment'
const strFormat = 'YYYY-MM-DD HH:mm'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

// import event category constants
import EventCategories from './CalendarEventCategories.js'

const arrExtendedProps = ['category', 'location', 'description']

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async params => {
  const response = await useJwt.getCalendarEventData(params)  
  if (response.data) {    
    const arrResult = []
    response.data.events.forEach(event => {
      // process Date/Time
      
      const startTime = moment.utc(event.start, strFormat).local(false).toDate()
      const endTime = moment.utc(event.end, strFormat).local(false).toDate()  
      
      // construct calendar item
      const newItem = {}
      const extendedProps = {}
      const arrExceptProps = ['user_id', 'start', 'end']
      
      Object.keys(event).forEach(key => {
        if (arrExtendedProps.includes(key)) {
          extendedProps[key] = event[key]
        } else if (!arrExceptProps.includes(key)) {
          newItem[key] = event[key]
        }
      })
      arrResult.push({ ...newItem, start: startTime, end: endTime, extendedProps })
    })
    return arrResult
  } else {
    return []
  }
})

export const addEvent = createAsyncThunk('appCalendar/addEvent', async (event, { dispatch, getState }) => {  
  await useJwt.addCalendarEvent(event)
  await dispatch(fetchEvents({category: getState().calendar.selectedCategories}))
  return event
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, { dispatch, getState }) => {
  await useJwt.updateCalendarEvent(event)
  await dispatch(fetchEvents({category: getState().calendar.selectedCategories}))
  return event
})

export const updateFilter = createAsyncThunk('appCalendar/updateFilter', async (filter, { dispatch, getState }) => {
  if (getState().calendar.selectedCategories.includes(filter)) {
    await dispatch(fetchEvents({category: getState().calendar.selectedCategories.filter(i => i !== filter)}))
  } else {
    await dispatch(fetchEvents({category: [...getState().calendar.selectedCategories, filter]}))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, { dispatch }) => {
  if (value === true) {
    await dispatch(fetchEvents({category: [...EventCategories]}))
  } else {
    await dispatch(fetchEvents({category: []}))
  }
  return value
})

export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async id => {
  await useJwt.deleteCalendarEvent({ id })
  return id
})

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: {},
    selectedCategories: [...EventCategories]
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCategories.includes(action.payload)) {
          state.selectedCategories.splice(state.selectedCategories.indexOf(action.payload), 1)
        } else {
          state.selectedCategories.push(action.payload)
        }
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = [...EventCategories]
        } else {
          selected = []
        }
        state.selectedCategories = selected
      })
  }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer
