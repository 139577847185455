// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appPowerBIAccounts/getAllData', async () => {  
  const response = await useJwt.getAllPowerBIAccountData()
  return response.data
})

export const getData = createAsyncThunk('appPowerBIAccounts/getData', async params => {
  const response = await useJwt.getPowerBIAccountData(params)
  return {
    params,
    data: response.data.accounts,
    totalPages: response.data.total
  }
})

export const getPowerBIAccount = createAsyncThunk('appPowerBIAccounts/getAccount', async id => {
  const response = await useJwt.getPowerBIAccount({ id })
  return response.data
})

export const addPowerBIAccount = createAsyncThunk('appPowerBIAccounts/addAccount', async (account, { dispatch, getState }) => {
  await useJwt.addPowerBIAccount(account)  
  await dispatch(getData(getState().accounts.params))
  await dispatch(getAllData())
  return account
})

export const updatePowerBIAccount = createAsyncThunk('appPowerBIAccounts/updateAccount', async (account, { dispatch, getState }) => {
  await useJwt.updatePowerBIAccount(account)  
  await dispatch(getData(getState().accounts.params))
  await dispatch(getAllData())
  return account
})

export const deletePowerBIAccount = createAsyncThunk('appPowerBIAccounts/deleteAccount', async (id, { dispatch, getState }) => {
  await useJwt.deletePowerBIAccount({ id })
  await dispatch(getData(getState().accounts.params))
  await dispatch(getAllData())
  return id
})

export const appPowerBIAccountSlice = createSlice({
  name: 'appPowerBIAccounts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAccount: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPowerBIAccount.fulfilled, (state, action) => {
        state.selectedAccount = action.payload
      })
      .addCase(updatePowerBIAccount.fulfilled, (state, action) => {
        state.selectedAccount = {...state.selectedAccount, ...action.payload }
      })
  }  
})

export default appPowerBIAccountSlice.reducer
