// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appTeams/getAllData', async () => {  
  const response = await useJwt.getAllTeamData()
  return response.data
})

export const getData = createAsyncThunk('appTeams/getData', async params => {
  const response = await useJwt.getTeamData(params)
  return {
    params,
    data: response.data.teams,
    totalPages: response.data.total
  }
})

export const getTeam = createAsyncThunk('appTeams/getTeam', async id => {
  const response = await useJwt.getTeam({ id })
  return response.data
})

export const addTeam = createAsyncThunk('appTeams/addTeam', async (team, { dispatch, getState }) => {
  await useJwt.addTeam(team)  
  await dispatch(getData(getState().teams.params))
  await dispatch(getAllData())
  return team
})

export const updateTeam = createAsyncThunk('appTeams/updateTeam', async (team, { dispatch, getState }) => {
  await useJwt.updateTeam(team)  
  await dispatch(getData(getState().teams.params))
  await dispatch(getAllData())
  return team
})

export const deleteTeam = createAsyncThunk('appTeams/deleteTeam', async (id, { dispatch, getState }) => {
  await useJwt.deleteTeam({ id })
  await dispatch(getData(getState().teams.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('appTeams/toggleLoading', async () => {
  return true
})


export const appTeamsSlice = createSlice({
  name: 'appTeams',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTeam: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getTeam.fulfilled, (state, action) => {
        state.selectedTeam = action.payload
        state.loading = false
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.selectedTeam = {...state.selectedTeam, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appTeamsSlice.reducer
