// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appGroups/getAllData', async () => {  
  const response = await useJwt.getAllCompanyGroupData()
  return response.data
})

export const getData = createAsyncThunk('appGroups/getData', async params => {
  const response = await useJwt.getCompanyGroupData(params)
  return {
    params,
    data: response.data.groups,
    totalPages: response.data.total
  }
})

export const getGroup = createAsyncThunk('appGroups/getGroup', async id => {
  const response = await useJwt.getCompanyGroup({ id })
  return response.data
})

export const addGroup = createAsyncThunk('appGroups/addGroup', async (group, { dispatch, getState }) => {
  await useJwt.addCompanyGroup(group)  
  await dispatch(getData(getState().groups.params))
  await dispatch(getAllData())
  return group
})

export const updateGroup = createAsyncThunk('appGroups/updateGroup', async (group, { dispatch, getState }) => {
  await useJwt.updateCompanyGroup(group)  
  await dispatch(getData(getState().groups.params))
  await dispatch(getAllData())
  return group
})

export const deleteGroup = createAsyncThunk('appGroups/deleteGroup', async (id, { dispatch, getState }) => {
  await useJwt.deleteCompanyGroup({ id })
  await dispatch(getData(getState().groups.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('appGroups/toggleLoading', async () => {
  return true
})


export const appGroupsSlice = createSlice({
  name: 'appGroups',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedGroup: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getGroup.fulfilled, (state, action) => {
        state.selectedGroup = action.payload
        state.loading = false
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.selectedGroup = {...state.selectedGroup, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appGroupsSlice.reducer
