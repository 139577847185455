// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getSessionData = createAsyncThunk('bardAIChat/getSessionData', async () => {
  const response = await useJwt.getAllBardAISessions()
  return {
    data: response.data.conversations,
    totalPages: response.data.total
  }
})

export const getSessionHistories = createAsyncThunk('bardAIChat/getSessionHistories', async params => {
  const response = await useJwt.getAllBardAIHistories(params)
  return {
    data: response.data.records,
    totalPages: response.data.total
  }
})

export const getSession = createAsyncThunk('bardAIChat/getSession', async (params, { dispatch }) => {
  const { conversationID } = params  
  const response = await useJwt.getBardAISession(params)
  await dispatch(getSessionHistories({conversationID}))
  return response.data
})

export const addSession = createAsyncThunk('bardAIChat/addSession', async (_, { dispatch }) => {
  const response = await useJwt.createBardAISession()
  await dispatch(getSessionData())
  return response.data
})

export const initSession = createAsyncThunk('bardAIChat/initSession', async (_, { dispatch }) => {
  const response = await useJwt.initBardAISession()  
  const selectedSessionID = response.data.conversationID
  await dispatch(getSessionData())
  await dispatch(getSessionHistories({conversationID: selectedSessionID}))
  return response.data
})

export const updateSession = createAsyncThunk('bardAIChat/updateSession', async (session, { dispatch }) => {
  await useJwt.updateBardAISession(session)  
  await dispatch(getSessionData())
  return session
})

export const deleteSession = createAsyncThunk('bardAIChat/deleteSession', async (conversationID, { dispatch }) => {
  await useJwt.deleteBardAISession({ conversationID })
  await dispatch(initSession())
  return conversationID
})

export const toggleLoading = createAsyncThunk('bardAIChat/toggleLoading', async () => {
  return true
})

export const askToBot = createAsyncThunk('bardAIChat/askToBot', async (params, { dispatch }) => {
  try {
    const { conversationID } = params
    const response = await useJwt.askToBardAI(params)
    await dispatch(getSessionData())
    await dispatch(getSessionHistories({conversationID}))
    return response
  } catch (e) {
    return e
  }
})

export const appBardAISlice = createSlice({
  name: 'bardAIChat',
  initialState: {
    sessions: [],
    totalSessions: 1,
    selectedSession: null,
    historyData: [],
    totalHistory: 1,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addSession.fulfilled, (state, action) => {
        state.selectedSession = action.payload
        state.historyData = []
        state.totalHistory = 0
        state.loading = false
      })
      .addCase(initSession.fulfilled, (state, action) => {
        state.selectedSession = action.payload
        state.loading = false
      })
      .addCase(getSessionData.fulfilled, (state, action) => {
        state.sessions = action.payload.data
        state.totalSessions = action.payload.totalPages
        state.loading = false
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.selectedSession = action.payload
        state.loading = false
      })
      .addCase(updateSession.fulfilled, (state, action) => {
        state.selectedSession = {...state.selectedSession, ...action.payload }
      })
      .addCase(getSessionHistories.fulfilled, (state, action) => {
        state.historyData = action.payload.data
        state.totalHistory = action.payload.totalPages
        state.loading = false
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appBardAISlice.reducer
