// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appReports/getAllData', async () => {  
  const response = await useJwt.getAllReportsData()
  return response.data
})

export const getData = createAsyncThunk('appReports/getData', async params => {
  const response = await useJwt.getReportData(params)
  return {
    params,
    data: response.data.reports,
    totalPages: response.data.total
  }
})

export const getReport = createAsyncThunk('appReports/getReport', async id => {
  const response = await useJwt.getReport({ id })
  return response.data
})

export const addReport = createAsyncThunk('appReports/addReport', async (report, { dispatch, getState }) => {
  await useJwt.addReport(report)  
  await dispatch(getData(getState().reports.params))
  await dispatch(getAllData())
  return report
})

export const updateReport = createAsyncThunk('appReports/updateReport', async (report, { dispatch, getState }) => {
  await useJwt.updateReport(report)  
  await dispatch(getData(getState().reports.params))
  await dispatch(getAllData())
  return report
})

export const deleteReport = createAsyncThunk('appReports/deleteReport', async (id, { dispatch, getState }) => {
  await useJwt.deleteReport({ id })
  await dispatch(getData(getState().reports.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('appReports/toggleLoading', async () => {
  return true
})

export const appReportsSlice = createSlice({
  name: 'appReports',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedReport: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getReport.fulfilled, (state, action) => {
        state.selectedReport = action.payload
        state.loading = false
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        state.selectedReport = {...state.selectedReport, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appReportsSlice.reducer
