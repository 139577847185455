// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('pipelines/getAllData', async () => {  
  const response = await useJwt.getAllPipelinesData()
  return response.data.pipelines
})

export const getData = createAsyncThunk('pipelines/getData', async params => {
  const response = await useJwt.getPipelineData(params)
  return {
    params,
    data: response.data.pipelines,
    totalPages: response.data.total
  }
})

export const getPipeline = createAsyncThunk('pipelines/getPipeline', async id => {
  const response = await useJwt.getPipeline({ id })
  return response.data
})

export const addPipeline = createAsyncThunk('pipelines/addPipeline', async (pipeline, { dispatch, getState }) => {
  await useJwt.addPipeline(pipeline)  
  await dispatch(getData(getState().pipelines.params))
  await dispatch(getAllData())
  return pipeline
})

export const updatePipeline = createAsyncThunk('pipelines/updatePipeline', async (pipeline, { dispatch, getState }) => {
  await useJwt.updatePipeline(pipeline)  
  await dispatch(getData(getState().pipelines.params))
  await dispatch(getAllData())
  return pipeline
})

export const deletePipeline = createAsyncThunk('pipelines/deletePipeline', async (id, { dispatch, getState }) => {
  await useJwt.deletePipeline({ id })
  await dispatch(getData(getState().pipelines.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('pipelines/toggleLoading', async () => {
  return true
})

export const appPipelineSlice = createSlice({
  name: 'pipelines',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPipeline: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getPipeline.fulfilled, (state, action) => {
        state.selectedPipeline = action.payload
        state.loading = false
      })
      .addCase(updatePipeline.fulfilled, (state, action) => {
        state.selectedPipeline = {...state.selectedPipeline, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }  
})

export default appPipelineSlice.reducer
