// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appTrackings/getAllData', async () => {  
  const response = await useJwt.getAllTrackingData()
  return response.data
})

export const getData = createAsyncThunk('appTrackings/getData', async params => {
  const response = await useJwt.getTrackingData(params)
  return {
    params,
    data: response.data.opportunities,
    totalPages: response.data.total
  }
})

export const getTracking = createAsyncThunk('appTrackings/getTracking', async noticeid => {
  const response = await useJwt.getTracking({ noticeid })
  return response.data
})

export const addTracking = createAsyncThunk('appTrackings/addTracking', async (opportunity, { dispatch, getState }) => {
  await useJwt.addTracking(opportunity)  
  await dispatch(getData(getState().trackings.params))
  await dispatch(getAllData())
  return opportunity
})

export const updateTracking = createAsyncThunk('appTrackings/updateTracking', async (opportunity, { dispatch, getState }) => {
  await useJwt.updateTracking(opportunity)  
  await dispatch(getData(getState().trackings.params))
  await dispatch(getAllData())
  return opportunity
})

export const deleteTracking = createAsyncThunk('appTrackings/deleteTracking', async (noticeid, { dispatch, getState }) => {
  await useJwt.deleteTracking({ noticeid })
  await dispatch(getData(getState().trackings.params))
  await dispatch(getAllData())
  return noticeid
})

export const toggleLoading = createAsyncThunk('appTrackings/toggleLoading', async () => {
  return true
})

export const appTrackingsSlice = createSlice({
  name: 'appTrackings',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTracking: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.loading = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getTracking.fulfilled, (state, action) => {
        state.selectedTracking = action.payload
        state.loading = false
      })
      .addCase(updateTracking.fulfilled, (state, action) => {
        state.selectedTracking = {...state.selectedTracking, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appTrackingsSlice.reducer