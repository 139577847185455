// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await useJwt.getAllUserData()
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await useJwt.getUserData(params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  // const response = await axios.get('/api/users/user', { id })
  const response = await useJwt.getUser({ id })
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  // await axios.post('/apps/users/add-user', user)
  await useJwt.addUser(user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { dispatch, getState }) => {
  await useJwt.updateUser(user)  
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  // await axios.delete('/apps/users/delete', { id })
  await useJwt.deleteUser({ id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('appUsers/toggleLoading', async () => {
  return true
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = false
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.selectedUser = {...state.selectedUser, ...action.payload}        
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appUsersSlice.reducer
