// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import companies from '@src/views/apps/company/store'
import projects from '@src/views/apps/project/store'
import reports from '@src/views/apps/report/store'
import accounts from '@src/views/settings/powerbi-account/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import pipelines from '@src/views/settings/pipeline/store'
import widgetTemplates from '@src/views/settings/dashboard-widget-template/store'
import dashboards from '@src/views/dashboard/custom/store'
import groups from '@src/views/apps/group/store'
import teams from '@src/views/apps/team/store'
import bardAIChats from '@src/views/apps/ai-chat/bard/store'
import trackings from '@src/views/apps/ai-chat/tracking/store'
import onboardings from '@src/views/apps/onboarding/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  companies,
  projects,
  reports,
  accounts,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  pipelines,
  widgetTemplates,
  dashboards,
  groups,
  teams,
  bard: bardAIChats,
  trackings,
  onboardings
}

export default rootReducer
