// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appWidgetTemplates/getAllData', async () => {  
  const response = await useJwt.getAllWidgetTemplateData()
  return response.data
})

export const getData = createAsyncThunk('appWidgetTemplates/getData', async params => {
  const response = await useJwt.getWidgetTemplateData(params)
  return {
    params,
    data: response.data.templates,
    totalPages: response.data.total
  }
})

export const getTemplate = createAsyncThunk('appWidgetTemplates/getTemplate', async id => {
  const response = await useJwt.getWidgetTemplate({ id })  
  return response.data
})

export const addTemplate = createAsyncThunk('appWidgetTemplates/addTemplate', async (template, { dispatch, getState }) => {
  await useJwt.addWidgetTemplate(template)  
  await dispatch(getData(getState().widgetTemplates.params))
  await dispatch(getAllData())
  return template
})

export const updateTemplate = createAsyncThunk('appWidgetTemplates/updateTemplate', async (template, { dispatch, getState }) => {
  await useJwt.updateWidgetTemplate(template)  
  await dispatch(getData(getState().widgetTemplates.params))
  await dispatch(getAllData())
  return template
})

export const deleteTemplate = createAsyncThunk('appWidgetTemplates/deleteTemplate', async (id, { dispatch, getState }) => {
  await useJwt.deleteWidgetTemplate({ id })
  await dispatch(getData(getState().widgetTemplates.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('appWidgetTemplates/toggleLoading', async () => {
  return true
})


export const appWidgetTemplatesSlice = createSlice({
  name: 'appWidgetTemplates',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTemplate: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.selectedTemplate = action.payload
        state.loading = false
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.selectedTemplate = {...state.selectedTemplate, ...action.payload }
        state.loading = false
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appWidgetTemplatesSlice.reducer
